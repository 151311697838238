.logo {
  display: block;
  height: var(--header-height);
  line-height: var(--header-height);
  width: 260px;
  text-align: center;
  transition: all 0.3s ease;
  position: relative;
}

.logo__small {
  font-size: 30px;
  transform: translateX(-124px);
}

.logo__medium {
  font-size: 40px;
}

.hub {
  background: #13c2c2;
  box-shadow: #ffec3d 6px 4px 0px 0px, #f759ab -4px 8px 0px 0px;
  border-radius: 5px;
  color: #1b1b1b;
  display: inline-block;
  line-height: 44px;
  padding: 0px 5px;
  margin-left: 8px;
}

.data {
  opacity: 1;
  transition: opacity 0.3s ease;
}
.data__light {
  color: #000;
}

.data__dark {
  color: #fff;
}

.data__hidden {
  opacity: 0;
}
