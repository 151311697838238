.header {
  background: #fff !important;
  height: var(--header-height) !important;
  padding-inline: 16px !important;
  box-shadow: #000 0px -5px 5px 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content {
  padding: 16px;
}

.sidebar {
  display: none;
  background: #1b1b1b !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px;
}

.layout {
  min-height: 100vh;
}

.sidebarTrigger {
  display: none;
}

.mobileMenu {
  background: #1b1b1b;
}

.mobileMenuButton {
  display: block;
}
.mobileLogo {
  display: block;
  transform: scale(0.8);
}

@media (min-width: 1024px) {
  .mobileLogo {
    display: none;
  }
  .mobileMenuButton {
    display: none;
  }
  .sidebarTrigger {
    display: block;
  }
  .sidebar {
    display: block;
  }
}
