.taskListTrigger {
  position: fixed;
  left: 30px;
  bottom: 30px;
}

.inlineRetry {
  margin: 0 5px;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 70%;
  align-items: stretch;
}
