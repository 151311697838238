.avatar {
  background: #1b1b1b;
  box-shadow: #13c2c2 -2px -2px 0px 0px, #ffec3d 2px -2px 0px 0px,
    #f759ab 0px 2px 0px 0px;
  cursor: pointer;
}

.teamsList {
  padding-left: 16px;
}
.teamsList li {
  margin-bottom: 8px;
}
